.SlideIn-appear {
  opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transition: all 1s;
}
.SlideIn-enter {
  opacity: 0;
  transition: all 1s;
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transition: all 1s;
}
.SlideIn-leave {
  opacity: 1;
  transition: all 1s;
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: all 1s;
}

.SlideOut-appear {
  opacity: 0;
  transition: all 1s;
}

.SlideOut-appear.SlideOut-appear-active {
  opacity: 1;
  transition: all 1s;
}
.SlideOut-enter {
  opacity: 0;
  transition: all 1s;
}
.SlideOut-enter.SlideOut-enter-active {
  opacity: 1;
  transition: all 1s;
}
.SlideOut-leave {
  opacity: 1;
  transition: all 1s;
}
.SlideOut-leave.SlideOut-leave-active {
  opacity: 0;
  transition: all 1s;
}
