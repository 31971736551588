/*Slide out to the right, delaying 1.5s so it concides with the 'selling now' slide in*/
/* Shift the margin-top and padding to move  everything upward after the main
transition.  Do this after the delay, plus the slide right animation. */
.nextUp-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}
.nextUp-enter-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transition: transform 700ms ease-in-out;
}
.nextUp-exit {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  max-height: 300px;
}
.nextUp-exit-active {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  max-height: 0px;
  margin: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  transition: transform 700ms ease-in-out 700ms, max-height 1s linear 1.4s,
    margin 0s linear 1.4s, padding-top 1.6s linear 0.2s,
    padding-bottom 1.6s linear 0.2s;
}

/* Slide old entry out from the right, and then a new one in from the left.,*/
.sellingNow-enter {
  transform: translateX(-200%);
  -webkit-transform: translateX(-100%);
}
.sellingNow-enter-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transition: transform 700ms ease-in-out;
}
.sellingNow-exit {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
}
.sellingNow-exit-active {
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transition: transform 700ms ease-in-out;
}

/* Slide new entries in from the left */
/* Use a margin-top to move everything downward after the main transition -
 delay the transform by the same time as the max-height runs so it moves, then a
   new one slides in. */
.recentlySold-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  margin-top: -100%;
}
.recentlySold-enter-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  margin-top: 6px;
  transition: transform 700ms ease-in-out 1s, margin 1s linear,
    padding 1s linear;
}
.recentlySold-exit {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
}
.recentlySold-exit-active {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transition: transform 700ms ease-in-out;
}

/* Show more/less buttons.  Not really getting a lot for this animation, it's just
living in a transition, so, when in Rome.
 */
.show-buttons-enter {
  opacity: 0;
}
.show-buttons-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.show-buttons-exit {
  opacity: 1;
}
.show-buttons-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

/* Very simple fade in.
Setting a `className="fadeIn"` will fade the text in on the FIRST RENDER of the component.
In most cases you'll probably want a key=[somethinguniqueforthedata]
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
.fadeIn {
  animation: fadeIn 4s;
}
